import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { HelpText } from "../HelpText";
import { TitleText } from "../../TitleText";
import { Text } from "../../Text";

export const RadioInput = (props) => {
  const {
    name,
    description = "",
    value = "",
    error = "",
    isRequired = false,
    showError = false,
    helpText = "",
    initializable = false,
    onChange = () => {},
    updateVisible = () => {},
    tooltipPercent,
    options = [],
  } = props;
  const [missingField, setMissingField] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [displayError, setDisplayError] = useState(false);

  useEffect(() => {
    if (!!error && (isTouched || showError)) setDisplayError(true);
    else setDisplayError(false);
  }, [error, isTouched, showError]);

  useEffect(() => {
    updateVisible(name, true);
    return () => {
      if (initializable) onChange(name, "");

      updateVisible(name, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (selected) => {
    setIsTouched(true);

    onChange(name, selected);
  };

  useEffect(() => {
    if (name && !value?.toString()?.length && isRequired) {
      setMissingField(true);
    } else {
      setMissingField(false);
    }
  }, [value, isRequired, name]);

  return (
    <div className="d-flex flex-column w-100 justify-content-between h-100">
      <div className="d-flex align-items-start mb-2">
        <label htmlFor={name} className="d-flex">
          <TitleText
            style={
              (error && displayError) || missingField
                ? { color: "#a51e22" }
                : {}
            }
            children={`${description}${
              !isRequired && description ? " (optional)" : ""
            }`}
          />
          {/* {isRequired && <Sterik />} */}
        </label>
        {helpText && (
          <HelpText helpText={helpText} tooltipPercent={tooltipPercent} />
        )}
      </div>
      <div className="d-flex flex-column gap-2">
        <input
          style={{ display: "none" }}
          id={`${name}_1`}
          type="radio"
          name="radio"
          onClick={() => handleChange(true)}
          onChange={() => handleChange(true)}
          onBlur={() => setIsTouched(true)}
        />
        <label
          className="d-flex flex-row align-items-center w-100 gap-2 cursor-pointer"
          htmlFor={`${name}_1`}
        >
          <div
            style={{
              border: `1px solid ${
                isEqual(value, true) ? "#A41B1E" : "#758283"
              }`,
              borderRadius: "20px",
              height: "20px",
              width: "20px",
            }}
            className="d-flex flex-row align-items-center justify-content-center  border-2 rounded-circle"
          >
            <div
              style={{ width: "10px", height: "10px" }}
              className={`dot  bg-danger border-2 rounded-circle ${
                isEqual(value, true) ? "d-block" : "d-none"
              }`}
            ></div>
          </div>
          <Text>{options?.length ? options[0].label : "Yes"}</Text>
        </label>

        <input
          style={{ display: "none" }}
          className=" ms-2"
          id={`${name}_2`}
          type="radio"
          name="radio"
          onClick={() => handleChange(false)}
          onChange={() => handleChange(false)}
          onBlur={() => setIsTouched(true)}
        />
        <label
          className="d-flex flex-row align-items-center w-100 gap-2 cursor-pointer"
          htmlFor={`${name}_2`}
        >
          <div
            style={{
              border: `1px solid ${
                isEqual(value, false) ? "#A41B1E" : "#758283"
              }`,
              borderRadius: "20px",
              height: "20px",
              width: "20px",
            }}
            className="d-flex flex-row align-items-center justify-content-center  border-2 rounded-circle"
          >
            <div
              style={{ width: "10px", height: "10px" }}
              className={`dot bg-danger border-2 rounded-circle ${
                isEqual(value, false) ? "d-block" : "d-none"
              }`}
            ></div>
          </div>
          <Text>{options?.length ? options[1].label : "No"}</Text>
        </label>
      </div>
      {error && displayError && (
        <div
          style={{ color: "#a51e22", fontSize: "0.8rem" }}
          className={`pt-1  outline-none text-danger h-2 sm:h-3 w-100 text-sm sm:text-base lg:text-lg leading-4 sm:leading-5 lg:leading-6`}
        >
          {error}
        </div>
      )}
    </div>
  );
};

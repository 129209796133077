import React, { useCallback, useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { CountryColors } from "../../../../common/constants";
import { useDispatch, useSelector } from "react-redux";
import useCountryList from "../../../../assets/shared/hooks/useCountryList";
import {
  updateFilter,
  updateSelectedRoute,
} from "../../../../slices/filledApplications";
import { primaryColor } from "../../../../common/env";
import { useAnalyticsFilter } from "../analyticsContext";

const BarChart = ({ homeCountrySelected, hostCountrySelected }) => {
  const dispatch = useDispatch();
  const { getCountryName } = useCountryList();
  const { filterData } = useAnalyticsFilter();
  const { home_country, host_country, ...filteredData } = filterData;
  const { mostCommonRoutes, selectedRoute } = useSelector(
    (state) => state.filledApplications
  );
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const selectedRouteRef = useRef(selectedRoute);

  const graphDataStructureHandler = useCallback(
    (routes) => {
      const categories =
        routes?.map((route) => {
          const country = route?.country;
          return `${country ? getCountryName(country) : ""}`;
        }) || [];
      setOptions({
        chart: {
          type: "bar",
          events: {
            dataPointSelection: (
              event,
              chartContext,
              { seriesIndex, dataPointIndex, w }
            ) => {
              const clickedData =
                w.config.series[seriesIndex].data[dataPointIndex];
              const newSelectedRoute = routes?.find(
                (_item) => _item.country === clickedData.country
              );

              if (clickedData.country === selectedRouteRef.current?.country) {
                dispatch(updateSelectedRoute({}));
                dispatch(updateFilter(filterData));
              } else {
                dispatch(updateSelectedRoute(newSelectedRoute));

                dispatch(
                  updateFilter({
                    ...filteredData,
                    home_country: clickedData.country,
                  })
                );
              }
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: true, // Enable distributed bars
          },
        },
        xaxis: {
          categories: categories,
        },
        yaxis: {
          title: {
            text: "No. of trips",
          },
        },
        legend: {
          show: false, // Hide the legend
        },
        dataLabels: {
          enabled: false, // Disable data labels
        },
        tooltip: {
          y: {
            formatter: function (val) {
              // Customize the value as needed
              return val.toLocaleString();
            },
          },
        },
      });

      const series =
        routes?.map((route, index) => {
          const country = route?.country;
          const name = `${country ? getCountryName(country) : ""}`;

          return {
            x: name,
            y: route.count.toLocaleString(),
            fillColor:
              selectedRoute?.country === country
                ? process.env.REACT_APP_LIGHT_PRIMARY
                : CountryColors[`COUNTRY${index + 1}`],
            country,
          };
        }) || [];

      setSeries([
        {
          name: "Series 1",
          data: series,
        },
      ]);
    },
    [getCountryName, dispatch, selectedRoute.country]
  );

  useEffect(() => {
    graphDataStructureHandler(mostCommonRoutes);
  }, [mostCommonRoutes, graphDataStructureHandler]);

  // Update the ref whenever selectedRoute changes
  useEffect(() => {
    selectedRouteRef.current = selectedRoute;
  }, [selectedRoute]);

  useEffect(() => {
    if (homeCountrySelected) {
      setSelectedCountry("Home");
    } else if (hostCountrySelected) {
      setSelectedCountry("Destination");
    } else {
      setSelectedCountry("");
    }
  }, [homeCountrySelected, hostCountrySelected]);

  return (
    <div
      style={{
        borderRadius: "20px",
        height: "100%",
        boxShadow: " 0px 4px 40px 0px rgba(0, 0, 0, 0.1)",
      }}
      className="p-2 mt-2"
    >
      <h4 style={{ fontSize: "1rem" }} className="mt-2 mx-2">
        Most common
        {selectedCountry ? (
          <>
            : <span style={{ color: primaryColor }}> {selectedCountry} </span>{" "}
            countries
          </>
        ) : (
          " countries"
        )}
      </h4>
      {series[0]?.data.length === 0 ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          No data available
        </div>
      ) : (
        <Chart
          options={options}
          series={series}
          type="bar"
          height={"100%"}
          width={"100%"}
        />
      )}
    </div>
  );
};

export default BarChart;

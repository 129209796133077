import React, { useCallback, useEffect, useState } from "react";
import sortIcon from "../../../assets/images/sort-icon.svg";
import { MyModal, TextInput } from "../../../components/common";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountriesList,
  getPersonaEntities,
  updatePersonaEntities,
} from "../../../slices/dashboard";
import useCountryList from "../../../assets/shared/hooks/useCountryList";
import { Loading } from "../../../components/Loading.js";
import { useNavigate } from "react-router-dom";
import { TableLaoding } from "../../../components/common/Loading/TableLoading.js";
import debounce from "lodash/debounce";
import Icon, { ICON_NAMES } from "../../../components/Icone/index.jsx";
import styles from "./index.module.css";
import { startQuestionareLoading } from "../../../slices/questionnaire.js";
import DeleteModal from "./components/delete-modal.jsx";
import AddEntitiesModal from "./components/add-entities-modal.jsx";
import { extractOrdering } from "../../../functions/extractOrderingFromTableSort.js";
import { getFormattedDate } from "../../../functions/getFormattedDate.js";

const API_URL = process.env.REACT_APP_BACK_END_URL;

export const CompleteQuestionnaire = () => {
  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getCountryName } = useCountryList();
  const {
    personaEntities,
    loading,
    nextPageUrl,
    previousPageUrl,
    page_size,
    count,
  } = useSelector((state) => state.dashboard);
  const [addEntitiesModal, setAddEntitiesModal] = React.useState(false);
  const [updateEntryModal, setUpdateEntryModal] = React.useState("");
  const [entityUpdateName, setEntityUpdateName] = React.useState("");
  const [searchText, setSearchText] = useState("");

  const [saveLoading, setSaveLoading] = useState(false);
  const [sortCondition, setSortCondition] = useState({});
  const [deleteEntity, setDeleteEntity] = useState("");
  const [pageNumber, setPageNumber] = useState(1);

  const handleSorting = (field) => {
    setSortCondition((prev) => {
      let order = "";

      if (prev.field === field) {
        order = prev.order === "" ? "-" : "";
      }

      return {
        field,
        order,
      };
    });
  };

  useEffect(() => {
    const { field, order } = sortCondition;

    if (field) {
      const url =
        API_URL +
        `personas/entities/?compliance_type=A1,MSW-A1,COC&ordering=${order}${field}`;
      // Setting sort condition to localStorage to retain it after user navigates away
      localStorage.setItem(`questionnaireSort`, url);
      getPersonaEntitiesHandler(url);
    }
  }, [sortCondition]);

  const getPersonaEntitiesHandler = (url) => {
    dispatch(getPersonaEntities(url))
      .unwrap()
      .then(() => {})
      .catch(() => {});
  };

  const getCountryListHandler = () => {
    dispatch(getCountriesList())
      .unwrap()
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    const questionnaireSort = localStorage.getItem(`questionnaireSort`);

    if (questionnaireSort) setSortCondition(extractOrdering(questionnaireSort));
    getPersonaEntitiesHandler(questionnaireSort ? questionnaireSort : null);
    getCountryListHandler();
  }, []);

  const fetchNextPage = () => {
    if (nextPageUrl) {
      const nextPage = Number(pageNumber) + 1;
      setPageNumber(nextPage);
      getPersonaEntitiesHandler(nextPageUrl);
    }
  };

  const fetchPreviousPage = () => {
    if (previousPageUrl) {
      const previousPage = Number(pageNumber) - 1;
      setPageNumber(previousPage);
      getPersonaEntitiesHandler(previousPageUrl);
    }
  };

  const handleGetForm = (item, address, fromModal) => {
    dispatch(startQuestionareLoading());
    navigate("/questionnaire", {
      state: { item, address, fromModal },
    });
  };

  const debouncedGetPersonaEntitiesHandler = useCallback(
    debounce((url) => {
      getPersonaEntitiesHandler(url);
    }, 1300),
    []
  );

  const handleSearch = (value) => {
    if (!value) {
      debouncedGetPersonaEntitiesHandler();
    } else if (value.length) {
      const url =
        API_URL +
        `personas/entities/?compliance_type=A1,MSW-A1,COC&search=${value}`;
      debouncedGetPersonaEntitiesHandler(url);
    }
    setSearchText(value);
  };

  const totalPages = Math.ceil(count / page_size) || 0;

  return (
    <main>
      {loading ? (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50"
          style={{
            zIndex: "9999",
          }}
        >
          <TableLaoding />
        </div>
      ) : null}
      <section className="listing-section">
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="d-flex gap-3">
              <h2>Complete questionnaire</h2>
              {/* <HelpText helpText={"Help text here!"} /> */}
            </div>
            <p>
              Please complete the following questionnaires which are required
              for the filing of A1 applications in each country.
            </p>
            <div className="d-flex mb-3 flex-wrap flex-md-nowrap gap-2">
              <div className="w-100">
                <div className="w-50" style={{ minWidth: "200px" }}>
                  <TextInput
                    label="Entity"
                    value={searchText}
                    isRequired={false}
                    placeHolder="Search here"
                    onChange={(name, value) => {
                      handleSearch(value);
                    }}
                    Icon={
                      <div className="pb-1">
                        <i className="h-100 bi bi-search" />
                      </div>
                    }
                  />
                </div>
              </div>
              <div className="w-100 text-end">
                <button
                  style={{
                    backgroundColor: themeColor,
                    borderColor: themeColor,
                  }}
                  type="button"
                  className="btn btn-danger"
                  onClick={() => setAddEntitiesModal(true)}
                >
                  Add entity
                </button>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      <div className="d-flex align-items-center gap-2">
                        <img
                          onClick={() => {
                            handleSorting("country_name");
                          }}
                          src={sortIcon}
                          alt="sort-icon"
                          style={{ cursor: "pointer" }}
                        />
                        <div>Country</div>
                      </div>
                    </th>
                    <th>
                      <div className="d-flex align-items-center gap-2">
                        <img
                          onClick={() => {
                            handleSorting("name");
                          }}
                          src={sortIcon}
                          alt="sort-icon"
                          style={{ cursor: "pointer" }}
                        />
                        <div>Employing entity</div>
                      </div>
                    </th>
                    <th className="position-relative">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          onClick={() => {
                            handleSorting("status");
                          }}
                          src={sortIcon}
                          alt="sort-icon"
                          style={{ cursor: "pointer" }}
                        />
                        <div>Status</div>
                      </div>
                    </th>
                    <th className="position-relative">
                      <div className="d-flex align-items-center gap-2">
                        <div>Action</div>
                      </div>
                    </th>
                    <th className="position-relative">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          onClick={() => {
                            handleSorting("updated_at");
                          }}
                          src={sortIcon}
                          alt="sort-icon"
                          style={{ cursor: "pointer" }}
                        />
                        <div>Last modified</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {personaEntities?.map((i, index) => {
                    return (
                      <tr key={index}>
                        <td>{getCountryName(i.country_code)}</td>
                        <td
                          onClick={() => {
                            setUpdateEntryModal(i);
                            setEntityUpdateName(i.name);
                          }}
                          className="cursor-pointer"
                        >
                          {i.name}
                          <Icon name={ICON_NAMES.EDIT} />
                        </td>
                        <td>
                          {i.is_complete ? (
                            "Complete"
                          ) : (
                            <div style={{ color: "#a51e22" }}>Incomplete</div>
                          )}
                        </td>
                        <td>
                          <div style={{ gap: "15px" }} className="d-flex">
                            <div
                              onClick={() => {
                                handleGetForm(i, {}, false);
                              }}
                              style={{ color: themeColor }}
                              className={`${styles.tableAction} cursor-pointer`}
                            >
                              <Icon name={ICON_NAMES.EDIT} />
                              <span>Edit</span>
                            </div>
                            <div
                              onClick={() => {
                                setDeleteEntity(i.uuid);
                              }}
                              style={{ color: themeColor }}
                              className={`${styles.tableAction} cursor-pointer`}
                            >
                              <Icon name={ICON_NAMES.DELETICONE} />
                              <span>Delete</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div
                            className="d-inline-block"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {getFormattedDate(i.updated_at)}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {previousPageUrl && (
                    <button
                      style={{
                        backgroundColor: themeColor,
                        borderColor: themeColor,
                      }}
                      type="button"
                      className="btn btn-danger"
                      onClick={fetchPreviousPage}
                      disabled={!previousPageUrl}
                    >
                      Previous page
                    </button>
                  )}
                </div>
                {totalPages ? (
                  <div style={{ marginBottom: "7px" }}>
                    <span style={{ fontWeight: "700" }}> Page </span>{" "}
                    {pageNumber} of {totalPages}
                  </div>
                ) : null}
                <div>
                  {nextPageUrl && (
                    <button
                      style={{
                        backgroundColor: themeColor,
                        borderColor: themeColor,
                      }}
                      type="button"
                      className="btn btn-danger"
                      onClick={fetchNextPage}
                      disabled={!nextPageUrl}
                    >
                      Next page
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {deleteEntity && (
          <DeleteModal
            getPersonaEntitiesHandler={getPersonaEntitiesHandler}
            deleteEntity={deleteEntity}
            setDeleteEntity={setDeleteEntity}
            saveLoading={saveLoading}
          />
        )}
        {addEntitiesModal && (
          <AddEntitiesModal
            setSaveLoading={setSaveLoading}
            saveLoading={saveLoading}
            handleGetForm={handleGetForm}
            setAddEntitiesModal={setAddEntitiesModal}
            getPersonaEntitiesHandler={getPersonaEntitiesHandler}
          />
        )}
        {updateEntryModal && (
          <MyModal onClose={() => setUpdateEntryModal("")}>
            {loading && <Loading />}
            <div className="w-100">
              <div className="d-flex justify-content-between flex-wrap">
                <h2>Update entity details</h2>
              </div>
              <div className="d-flex flex-wrap w-100 pt-5 justify-content-between">
                <div style={{ width: "100%" }}>
                  <label className="form-label">
                    Entity name
                    <span style={{ color: themeColor }}>*</span>
                  </label>
                  <input
                    value={entityUpdateName}
                    onChange={(e) => setEntityUpdateName(e.target.value)}
                    className="form-control"
                    placeholder="Enter entity  name"
                    style={{
                      paddingRight: "50px",
                    }}
                  />
                </div>
              </div>

              <div className="row w-100">
                <div className="col w-100">
                  <div className="row">
                    <div className="row mt-5">
                      <div className="col">
                        <div className="d-flex w-100 justify-content-center gap-3">
                          <button
                            style={{
                              color: themeColor,
                              borderColor: themeColor,
                              backgroundColor: "white",
                            }}
                            type="button"
                            className="btn"
                            onClick={() => {
                              setEntityUpdateName("");
                              setUpdateEntryModal("");
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={!entityUpdateName}
                            style={{
                              backgroundColor: themeColor,
                              borderColor: themeColor,
                            }}
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                              dispatch(
                                updatePersonaEntities({
                                  name: entityUpdateName,
                                  uuid: updateEntryModal.uuid,
                                })
                              )
                                .unwrap()
                                .then(() => {
                                  setEntityUpdateName("");
                                  setUpdateEntryModal("");
                                  getPersonaEntitiesHandler();
                                })
                                .catch(() => {});
                            }}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MyModal>
        )}
      </section>
    </main>
  );
};

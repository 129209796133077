import { clsx } from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import React from "react";
import SearchableSelect from "react-select";

import { HelpText } from "../HelpText";
import icon from "../../../assets/images/dropdown.svg";
import useCountryList from "../../../assets/shared/hooks/useCountryList";
import { TextInput } from "../TextInput";
import { TitleText } from "../../TitleText";
import Close from "../../../assets/images/close.svg";
import { useQuestionnaire } from "../../../pages/ActionRequired/Questionnaire/context";

export const FormSelect = React.memo(function Select(props) {
  const {
    description = "",
    isRequired = false,
    placeholder = "Click here to select",
    error = "",
    showError = false,
    mappings = {},
    choices = [],
    helpText = "",
    isSearchable = false,
    name = "",
    value = "",
    initializable = false,
    countryObjectValues = [],
    onChange = () => {},
    updateVisible = () => {},
    isSelectBox = true,
    minMenuHeight = 200,
    autoPopulatedValues,
    setAutoPopulatedValues,
  } = props;
  const [missingField, setMissingField] = useState(false);
  const { getCountryName } = useCountryList();
  const countryName = getCountryName(value);
  const [isTouched, setIsTouched] = useState(false);
  const [displayError, setDisplayError] = useState(false);

  const handleChange = useCallback(
    (newValue) => {
      setIsTouched(true);
      const selectedValue = newValue?.value || "";

      onChange(name, selectedValue);
      if (autoPopulatedValues?.[name]) {
        setAutoPopulatedValues((prev) => {
          const { [name]: _, ...rest } = prev;
          return rest;
        });
      }
    },
    [name, onChange]
  );

  useEffect(() => {
    if (!!error && (isTouched || showError)) setDisplayError(true);
    else setDisplayError(false);
  }, [error, isTouched, showError]);

  useEffect(() => {
    updateVisible(name, true);
    return () => {
      if (initializable) onChange(name, "");
      updateVisible(name, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOptions = useMemo(
    () => (options) => {
      return [
        ...options.map((choice) => ({
          value: choice,
          label: mappings?.[choice] ?? choice,
        })),
      ];
    },
    [mappings]
  );

  useEffect(() => {
    if (name && !value && isRequired) {
      setMissingField(true);
    } else {
      setMissingField(false);
    }
  }, [value, isRequired, name]);

  return (
    <>
      {!isSelectBox ? (
        <TextInput {...props} />
      ) : (
        <div className="d-flex flex-column w-100 position-relative justify-content-between h-100">
          <div className="d-flex items-start mb-2">
            <label htmlFor={name} className="d-flex">
              <TitleText
                style={
                  (error && displayError) || missingField
                    ? { color: "#a51e22" }
                    : {}
                }
                children={`${description}${
                  !isRequired && description ? " (optional)" : ""
                }`}
              />
              {/* {isRequired && <Sterik />} */}
            </label>
            {helpText && <HelpText helpText={helpText} className="pl-3" />}
          </div>
          <div
            style={{
              borderBottom: `2px solid ${
                error && displayError ? "#a51e22" : "#CECECE"
              }`,
            }}
            className="d-flex justify-content-between align-items-center  pb-2 w-100"
          >
            <SearchableSelect
              components={{
                DropdownIndicator: ({ innerProps }) => (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    {...innerProps}
                  >
                    <img
                      className="cursor-pointer"
                      src={icon}
                      alt="help"
                      width={17}
                      height={25}
                    />
                  </div>
                ),
              }}
              name={name}
              value={
                value
                  ? getOptions(
                      choices.length ? choices : countryObjectValues
                    ).find(
                      (_itm) =>
                        _itm.value === value || _itm.value === countryName
                    )
                  : null
              }
              options={getOptions(
                choices.length ? choices : countryObjectValues
              )}
              isSearchable={isSearchable}
              onBlur={() => setIsTouched(true)}
              onChange={(newValue) => handleChange(newValue)}
              placeholder={placeholder}
              menuPlacement="auto"
              minMenuHeight={minMenuHeight}
              styles={{
                menu: (styles) => ({ ...styles, zIndex: 9999 }),
              }}
              classNames={{
                control: ({ isFocused }) =>
                  clsx(
                    isFocused ? "border-0 shadow-none" : "border-0 shadow-none",
                    "border-0 bg-white cursor-pointer shadow-none min-height-15 outline-none p-0 rounded-0"
                  ),
                placeholder: () => {
                  return `.value-style`;
                },

                option: ({ isFocused, isSelected }) =>
                  clsx(
                    isFocused && `.value-styl`,
                    isSelected &&
                      `.value-styl after-content-check after-ml-2 after-text-success bg-secondary`,
                    `h-100 cursor-pointer rounded.value-styl`
                  ),
                container: () => `w-100`,
                singleValue: () =>
                  `.value-styl border-0 text-start pb-1 text-secondary custome-select-style ${
                    autoPopulatedValues &&
                    autoPopulatedValues?.[name] ===
                      getOptions(
                        choices.length ? choices : countryObjectValues
                      ).find(
                        (_itm) =>
                          _itm.value === value || _itm.value === countryName
                      )
                      ? "prefilled-color"
                      : "not-prefilled-color"
                  }`,
                indicatorsContainer: () => `d-flex justify-content-between`,
                valueContainer: () => `p-0 m-0 h-100`,
                indicatorSeparator: () => `d-none`,
                input: () => `p-0 m-0`,
              }}
              theme={(_theme) => ({
                ..._theme,
                colors: {
                  ..._theme.colors,
                  primary: `white`,
                },
              })}
            />
            {value && (
              <img
                className="bi-close cursor-pointer ms-2"
                src={Close}
                alt="close"
                onClick={() => onChange(name, "")}
              />
            )}
          </div>
        </div>
      )}
      {error && displayError && (
        <div
          style={{ color: "#a51e22", fontSize: "0.8rem" }}
          className={`bottom-25% left-0 right-0 text-start focus-visible:outline-none h-10px sm:h-15px w-full text-12px sm:text-14px lg:text-16px leading-15px sm:leading-17px lg:leading-19px`}
        >
          {error}
        </div>
      )}
    </>
  );
});

import React from "react";

import sortIcon from "../../../assets/images/sort-icon.svg";
import { useSelector } from "react-redux";
import useCountryList from "../../../assets/shared/hooks/useCountryList.js";
import { handleDownload } from "../../../utils/handleDownload.js";
import { HelpText } from "../../../components/common/index.jsx";
import { embedBaseUrlIntoUrl } from "../../../utils/fileConversions.js";
import { getFormattedDate } from "../../../functions/getFormattedDate.js";

export default function PendingPowerOfAttorneyTable({
  setSelectedEntity,
  setUploadPOA,
  fetchPreviousPage,
  fetchNextPage,
  handleSorting,
  pageNumber,
  sortCondition,
}) {
  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;
  const themeDisabledColor = process.env.REACT_APP_LIGHT_PRIMARY_DISABLE;
  const { getCountryName } = useCountryList();
  const { pendingPoaEntities, pendingNextPageUrl, pendingPreviousPageUrl } =
    useSelector((state) => state.powerOfAttorney);

  // HANDLES PAGE CHANGE
  const handlePageChange = (direction) => {
    const isPrevious = direction === "previous";
    const pendingUrl = isPrevious ? pendingPreviousPageUrl : pendingNextPageUrl;

    let url = embedBaseUrlIntoUrl(pendingUrl);
    const { order, field } = sortCondition;

    if (field) {
      url += `&ordering=${order}${field}`;
    }

    const newPageNumber = pageNumber.current + (isPrevious ? -1 : 1);
    pageNumber.current = newPageNumber;

    if (isPrevious) {
      fetchPreviousPage(url, false);
    } else {
      fetchNextPage(url, false);
    }
  };
  return (
    <div className="table-responsive">
      <div style={{ overflow: "auto" }}>
        <table className="table">
          <thead>
            <tr>
              <th className="position-sticky top-0">
                <div className="d-flex align-items-center gap-2">
                  <img
                    onClick={() => {
                      handleSorting("country_name", false, pageNumber.current);
                    }}
                    src={sortIcon}
                    alt="sort-icon"
                    style={{ cursor: "pointer" }}
                  />
                  <div>Country</div>
                </div>
              </th>
              <th className="position-sticky top-0">
                <div className="d-flex align-items-center gap-2">
                  <img
                    onClick={() => {
                      handleSorting("name", false, pageNumber.current);
                    }}
                    src={sortIcon}
                    alt="sort-icon"
                    style={{ cursor: "pointer" }}
                  />
                  <div>Employing entity</div>
                </div>
              </th>
              <th className="position-sticky top-0">
                <div className="d-flex align-items-center gap-2">
                  <div>POA status</div>
                </div>
              </th>
              <th className="position-sticky top-0">
                <div className="d-flex align-items-center gap-2">
                  <div>Instructions</div>
                </div>
              </th>
              <th className="position-sticky top-0">
                <div className="d-flex align-items-center gap-2">
                  <div>Download POA</div>
                </div>
              </th>
              <th className="position-sticky top-0">
                <div className="d-flex align-items-center gap-2">
                  <div>Upload POA</div>
                </div>
              </th>
              <th className="position-sticky top-0">
                <div className="d-flex align-items-center gap-2">
                  <div>Signed POA</div>
                </div>
              </th>
              <th className="position-sticky top-0">
                <div className="d-flex align-items-center gap-2">
                  <img
                    onClick={() => {
                      handleSorting("updated_at", false, pageNumber.current);
                    }}
                    src={sortIcon}
                    alt="sort-icon"
                    style={{ cursor: "pointer" }}
                  />
                  <div>Last modified</div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {/* NB: i.requires_employer_poa checks if poa is required and disables some actions based on that*/}
            {pendingPoaEntities.map((i, index) => {
              const isDownloadUrl =
                i?.is_online_submission === false ? true : i?.is_complete;
              const downloadUrl =
                i.template_poa_url || i.country_poa_pre_signed_urls[0];
              const reviewUrl = i.signed_poa_url;
              return (
                <tr key={index}>
                  <td
                    className={`${
                      i.requires_specific_person_signature
                        ? "prefilled-color"
                        : ""
                    }`}
                  >
                    {getCountryName(i.country_code)}
                  </td>
                  <td>{i.employer_sub_entity_name}</td>
                  <td>
                    {i.is_complete
                      ? "Submitted"
                      : !i.requires_employer_poa
                      ? "Not required"
                      : "Pending"}
                  </td>
                  <td
                    onClick={() => {
                      i.requires_employer_poa && setSelectedEntity(i);
                    }}
                  >
                    <div
                      style={{
                        color: i.requires_employer_poa ? themeColor : "inherit",
                        cursor: !i.requires_employer_poa
                          ? "not-allowed"
                          : "initial",
                      }}
                      className=" cursor-pointer "
                    >
                      Show Instructions
                    </div>
                  </td>
                  <td>
                    <div>
                      {!isDownloadUrl ? (
                        <div>
                          <HelpText
                            noPadding={true}
                            helpText="No POA template available."
                            isIconeHide={true}
                            content={<div>Download POA template</div>}
                          />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            if (!isDownloadUrl || !i.requires_employer_poa)
                              return;
                            const fileSavingName = `${getCountryName(
                              i.country_code
                            )} - ${
                              i.employer_sub_entity_name
                            } - Power of Attorney`;

                            handleDownload(downloadUrl, fileSavingName);
                          }}
                          style={{
                            color: `${
                              !i.requires_employer_poa
                                ? "inherit"
                                : isDownloadUrl
                                ? themeColor
                                : themeDisabledColor
                            }`,
                            cursor: `${
                              !i.requires_employer_poa
                                ? "not-allowed"
                                : isDownloadUrl
                                ? "pointer"
                                : "not-allowed"
                            }`,
                          }}
                        >
                          Download POA template
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div>
                      {!isDownloadUrl ? (
                        <div>
                          <HelpText
                            noPadding={true}
                            helpText="No POA upload required."
                            isIconeHide={true}
                            content={<div>Upload POA</div>}
                          />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            if (!isDownloadUrl || !i.requires_employer_poa)
                              return;
                            setUploadPOA(i);
                          }}
                          style={{
                            color: `${
                              !i.requires_employer_poa
                                ? "inherit"
                                : isDownloadUrl
                                ? themeColor
                                : themeDisabledColor
                            }`,
                            cursor: `${
                              !i.requires_employer_poa
                                ? "not-allowed"
                                : isDownloadUrl
                                ? "pointer"
                                : "not-allowed"
                            }`,
                          }}
                        >
                          Upload POA
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div>
                      {reviewUrl ? (
                        <div
                          onClick={() => {
                            handleDownload(reviewUrl, i.uuid + "poa_signed");
                          }}
                          className="text-danger cursor-pointer "
                        >
                          Review outdated POA
                        </div>
                      ) : i.requires_employer_poa ? (
                        "Not completed"
                      ) : (
                        "N/A"
                      )}
                    </div>
                  </td>
                  <td>
                    <div
                      className="d-inline-block"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {getFormattedDate(i.updated_at)}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-4">
        <div>
          {pendingPreviousPageUrl && (
            <button
              style={{
                backgroundColor: themeColor,
                borderColor: themeColor,
              }}
              type="button"
              className="btn btn-danger"
              onClick={() => handlePageChange("previous")}
              disabled={!pendingPreviousPageUrl}
            >
              Previous page
            </button>
          )}
        </div>
        <div>
          {pendingNextPageUrl && (
            <button
              style={{
                backgroundColor: themeColor,
                borderColor: themeColor,
              }}
              type="button"
              className="btn btn-danger"
              onClick={() => handlePageChange("next")}
              disabled={!pendingNextPageUrl}
            >
              Next page
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { CountryColors } from "../../../../common/constants";
import useCountryList from "../../../../assets/shared/hooks/useCountryList";
import { updateFilter } from "../../../../slices/filledApplications";
import { primaryColor } from "../../../../common/env";
import styles from "../styles.module.css";
import { useAnalyticsFilter } from "../analyticsContext";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const LineChart = ({ homeCountrySelected, hostCountrySelected }) => {
  const dispatch = useDispatch();
  const { getCountryName } = useCountryList();
  const { filterData } = useAnalyticsFilter();

  const { mostCommonRoutes } = useSelector((state) => state.filledApplications);
  const [series, setSeries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedMonth, setSelectedMonth] = useState();

  function getDatesForMonth(monthNumber) {
    if (monthNumber < 1 || monthNumber > 12) {
      return "Invalid month number. Please enter a number between 1 and 12.";
    }

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const firstDateOfMonth = new Date(currentYear, monthNumber - 1, 1);
    const firstDateOfNextMonth = new Date(currentYear, monthNumber, 1);

    function formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    }

    return {
      firstDateOfMonth: formatDate(firstDateOfMonth),
      firstDateOfNextMonth: formatDate(firstDateOfNextMonth),
    };
  }

  const optionsStructure = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      events: {
        click: function (event, chartContext, config) {
          if (config.dataPointIndex > -1) {
            const month = config.dataPointIndex + 1;

            const dates = getDatesForMonth(month);
            const created_after = dates.firstDateOfMonth;
            const created_before = dates.firstDateOfNextMonth;
            if (month === selectedMonth) {
              dispatch(updateFilter(filterData));
              setSelectedMonth();
            } else {
              setSelectedMonth(month);
              dispatch(
                updateFilter({
                  ...filterData,
                  created_after,
                  created_before,
                })
              );
            }
          }
        },
      },
    },
    xaxis: {
      categories: months,
    },
    colors: [
      CountryColors.COUNTRY1,
      CountryColors.COUNTRY2,
      CountryColors.COUNTRY3,
      CountryColors.COUNTRY4,
      CountryColors.COUNTRY5,
    ],
    stroke: {
      width: 2,
      curve: "smooth", // Ensure smooth lines between points
    },
    markers: {
      size: 0, // Hide the markers to emphasize the lines
    },
    legend: {
      show: false, // Hide the legend
    },
  };

  const graphDataStructureHandler = useCallback(
    (routes) => {
      const monthsData =
        routes
          ?.map((route) => {
            const singleMonthCount = {};
            route?.submissions_by_month?.forEach((submission) => {
              singleMonthCount[months[Number(submission.month) - 1]] =
                submission.count;
            });
            return singleMonthCount;
          })
          .filter((data) => Object.keys(data).length > 0) || [];

      const seriesData = (monthsData || []).map((monthData = {}, index) => {
        const homeCountry = routes.length && routes[index]?.country;

        return {
          name: `${homeCountry ? getCountryName(homeCountry) : ""}`,
          data: months.map((month) =>
            monthData[month]
              ? parseInt(monthData[month], 10).toLocaleString()
              : 0
          ),
        };
      });

      // Fallback seriesData with default months if monthsData is not provided
      if (seriesData.length === 0) {
        seriesData.push({
          name: "",
          data: months.map(() => 0), // Ensuring all months are present with a default value
        });
      }

      setSeries(seriesData);
    },
    [getCountryName]
  );

  useEffect(() => {
    graphDataStructureHandler(mostCommonRoutes);
  }, [mostCommonRoutes, graphDataStructureHandler]);

  useEffect(() => {
    if (homeCountrySelected) {
      setSelectedCountry("Home");
    } else if (hostCountrySelected) {
      setSelectedCountry("Destination");
    } else {
      setSelectedCountry("");
    }
  }, [homeCountrySelected, hostCountrySelected]);
  return (
    <div className={`${styles.analytics_LineGraph} p-2`}>
      <h4 style={{ fontSize: "1rem" }} className="mt-2 mx-2">
        Filing timeline
        {selectedCountry && (
          <>
            : <span style={{ color: primaryColor }}> {selectedCountry} </span>{" "}
            countries
          </>
        )}
      </h4>

      <Chart
        options={optionsStructure}
        series={series}
        type="line"
        height={"100%"}
        width={"100%"}
      />
    </div>
  );
};

export default LineChart;
